import { proxy, useSnapshot } from "valtio";

const state = proxy<{
  l1ChainId: string;
  l2ChainId: string;
  pgChainId: string;
  l1Rpc: string;
  l2Rpc: string;
  pgRpc: string;
  open: boolean;
  session: any;
  args: any;
  authorization: Array<any>;
  selected: string;
  // inputs: any;
  version: number;
  uri: string;
}>({
  l1ChainId: "eip155:1",
  l2ChainId: "eip155:324",
  pgChainId: 'eip155:80001',
  l1Rpc: '',
  l2Rpc: '',
  pgRpc: '',
  open: false,
  args: null,
  session: null,
  authorization: [],
  selected: "",
  // inputs: null,
  version: 0,
  uri: "",
});

export default state;
